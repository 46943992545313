<div class="content-with-sidebar" [ngClass]="{'fixed': sidebarV2}" (window:resize)="onResize($event)">
  <div class="sidebar-container" (mouseenter)="setFullSidebar(true)" (mouseleave)="setFullSidebar(false)" [ngClass]="{'sm': sideBar?.isSmallWidth }">
    <nz-affix>
      <section class="sidebar-affix-content" app-sidebar #sideBar (notifyPageTitle)="updatePageTitle($event)"></section>
    </nz-affix>
  </div>
  <div class="padding"></div>
  <div style="width: 100%; overflow-x: auto;background-color: #F0F2F5; position: relative;">
    <app-topnav #topBar></app-topnav>
    <div #mainContainer class="content" (mouseenter)="setFullSidebar(false)" [ngClass]="{'overflow-x-hidden': isOverFlowXHidden}">
      <div *ngIf="isLoadingMasterData == true" class="nodata">
        <i nz-icon nzType="loading" nzTheme="outline"></i>
      </div>
      <div *ngIf="isLoadingMasterData == 'NG'" class="nodata" style="flex-direction: column;">
        <span style="font-size: 16px; color: #969696;">Ops something went wrong, please try again later.</span>
        <a [href]="currentUrl" style="margin-top: 20px; font-weight: bold; font-size: 16px;">
          <i nz-icon nzType="sync" nzTheme="outline" style="margin-right: 10px;"></i>
          Reload
        </a>
      </div>
      <router-outlet *ngIf="isLoadingMasterData == 'OK'" (activate)="onRouterActivate($event)" (deactivate)="onRouterDeactivate($event)"></router-outlet>
    </div>
  </div>
</div>

