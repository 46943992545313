<div class="container-box">
  <div class="dashboard-child-container no-padding list">
    <div class="list-header" style="align-items: end;">
      <div driver-filter></div>
      <div class="flex1"></div>
    <button *ngIf="requirePermissions([PermissionCode.driver.create])" nz-button (click)="onBtnAdd()" style="height: 32px;"><i nz-icon nzType="plus" nzTheme="outline"></i>Create new driver</button>
  </div>

  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)"
      [nzScroll]="{y: tableHeight + 'px'}">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="warpId" nzWidth="120px">WARP ID</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="firstName">Name</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="phone">Phone</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="tractorNumber">Tractor Number</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="trailerNumber">Trailer Number</th>
          <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="carrier.basicInfo.name">Carrier</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a class="inherit" [routerLink]="getRouterLink(item)">{{item.warpId}}</a>
          </td>
          <td>
            <a [routerLink]="getRouterLink(item)">{{getFullName(item)}}</a>
          </td>
          <td>
            <a class="inherit" [routerLink]="getRouterLink(item)">{{displayPhone(item.phone)}}</a>
          </td>
          <td>
            {{item.tractorNumber}}
          </td>
          <td>
            {{item.trailerNumber}}
          </td>
          <td>
            <a class="link" [routerLink]="[routeAdminCarrierList, item.carrier.id]" target="_blank">{{getCarrierName(item.carrier)}}</a>
            <i *ngIf="isAdmin" nz-icon nzType="edit" nzTheme="outline" class="clickable left10" 
            nz-tooltip nzTooltipTitle="Change carrier"
            (click)="onBtnUpdateChangeCarrier(item)"></i>
          </td>
          <td>
            <when-by-for-list [data]="item.insert"></when-by-for-list>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
  </div>
</div>
