import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { BaseQuickbooksComponent } from "../base";
import { ResponseQuickbooksCustomer } from "@wearewarp/types/data-model/types/finance";
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'search-quickbooks-customer',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class SearchQuickbooksCustomer extends BaseQuickbooksComponent {

  @Input() accountInfo;
  @Input() finAccountId;
  @Input() selectedCustomer: ResponseQuickbooksCustomer;
  @Input() onCreateCustomer: () => void;
  public isLoadingCustomer = false;
  public listOfData: ResponseQuickbooksCustomer[] = [];
  public limit = 3;

  protected formGroupDeclaration: FormGroupDeclaration = {
    companyName: { label: "Customer name", required: true }
  };

  constructor(private modal: NzModalRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.selectedCustomer) {
      const _data = [ this.selectedCustomer ];
      this.buildDisplayInfo(_data);
      this.listOfData = _data;
    }
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  onBtnSearchCompany() {
    const companyName = this.getItemValue('companyName');
    if (!companyName) {
      this.showWarning('', `Please enter customer name`);
      return;
    }
    const params = {
      query: companyName,
    }
    this.isLoadingCustomer = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/search-customer`);
    url = Utils.appendQueryStringIntoUrl(url, params);
    this.api.GET(url).subscribe(
      resp => {
        let _data;
        if (this.selectedCustomer?.Id) {
          _data = (resp?.data?.list_data ?? []).filter(item => !this.isSelected(item));
          _data = [ this.selectedCustomer, ..._data];
        } else {
          _data = resp?.data?.list_data ?? [];
        }
        this.buildDisplayInfo(_data);
        this.listOfData = _data;
        this.isLoadingCustomer = false;
      }, err => {
        this.handleErrorQueryQuickbooks(err)
        if (this.selectedCustomer) {
          const _data = [ this.selectedCustomer ];
          this.buildDisplayInfo(_data);
          this.listOfData = _data;
        } else {
          this.listOfData = [];
        }
        this.isLoadingCustomer = false;
      }
    );
  }

  private buildDisplayInfo(items: ResponseQuickbooksCustomer[]) {
    for (let item of items) {
      item['displayInfo'] = {
        companyName: item.CompanyName,
        qbLink: this.isProduction ? `https://qbo.intuit.com/app/customerdetail?nameId=${item.Id}` : `https://sandbox.qbo.intuit.com/app/customerdetail?nameId=${item.Id}`,
        billAddr: item.BillAddr?.City ? this.getAddressText({
          city: item.BillAddr.City,
          street: item.BillAddr.Line1,
          street2: item.BillAddr.Line2,
          zipcode: item.BillAddr.PostalCode,
          state: item.BillAddr.CountrySubDivisionCode,
        }) : 'N/A',
        email: item.PrimaryEmailAddr?.Address || 'N/A',
        phone: item.PrimaryPhone?.FreeFormNumber || 'N/A',
        website: item.WebAddr?.URI || 'N/A',
      }
    }
  }

  isSelected(item: ResponseQuickbooksCustomer) {
    return this.selectedCustomer?.Id === item.Id;
  }

  onBtnConfirm(item: ResponseQuickbooksCustomer) {
    this.isLoadingCustomer = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${this.finAccountId}/quickbooks`);
    url = Utils.appendQueryStringIntoUrl(url, {type: 'add'});
    this.api.PUT(url, item).subscribe(
      resp => {
        this.selectedCustomer = item;
        this.showSuccess('Success', 'Relationship confirm successfully');
        this.isLoadingCustomer = false;
      }, err => {
        this.showErr(err)
        this.isLoadingCustomer = false;
      }
    );
  }

  onBtnRemove(item: ResponseQuickbooksCustomer) {
    this.isLoadingCustomer = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${this.finAccountId}/quickbooks`);
    url = Utils.appendQueryStringIntoUrl(url, {type: 'remove'});
    this.api.PUT(url, item).subscribe(
      resp => {
        this.selectedCustomer = null;
        this.showSuccess('Success', 'Relationship removed successfully');
        this.isLoadingCustomer = false;
      }, err => {
        this.showErr(err)
        this.isLoadingCustomer = false;
      }
    );
  }

  onBtnCreateCustomer() {
    this.closeDialog();
    this.onCreateCustomer();
  }

  
}
