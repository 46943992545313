import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { UserListComponent } from './users/list';
import { UserDetailComponent } from './users/detail';
import { PageError } from '../page-error/page-error';
import { CheckCanQuit } from '@services/check-can-quit';
import { ClientList } from './clients/list';
import { ClientDetail } from './clients/detail';
import { ListLocation } from './clients/list-location';
import { WarehouseList } from './warehouses/list';
import { WarehouseInventory } from './warehouses/warehouse-inventory';
import { CrossdockWarehouse } from './crossdock-warehouse';
import { DriverList } from './drivers/list';
import { DriverDetail } from './drivers/detail';
import { FinanceConfigDetail } from './finance/config';
import { ReportRevenue } from './carrier-sales/report-revenue';
import { ZipRate } from './zip-rate';
import { AuditLog } from './components/log/audit-log';
import { OperationalPerformance } from './operational-performance/list';
import { LoadsBooked } from './loads-booked/list';
import { RevenueBooked } from './revenue-booked/list';
import { Setting2FA } from './settings/2fa';
import { FreightQuote } from './freight-quote';
import { FreightQuoteFTL } from './freight-quote-ftl';
import { FreightQuoteFTLHistoryRequest } from './freight-quote-ftl/history-request';
import { FreightQuoteHistoryList } from './freight-quote-ftl/history-list';
import { RoutingProblemDetail } from './routing-tool/problems/detail';
import { RoutingProblemList } from './routing-tool/problems/list';
import { ShipmentInvoiceList } from './shipment-invoices/list';
import { FreightQuoteBook } from './freight-quote-ftl/book-quote';
import { DeliveryPlanningScreen } from './planning/screen';
import { LinehaulLaneListScreen } from './planning/linehaul/lane-list/screen';
import { WarehouseDetail } from './warehouses/detail';
import { PlanningSessionDetailScreen } from './planning/session-detail-screen';
import { ReportViewerScreen } from './report/viewer/screen';
import { ClientAdd } from './clients/add';
import { WarehouseShipment } from './crossdock/shipments';
import { NeedRoutingScreen } from './planning/need-routing';
import { LtlVolumeScreen } from './planning/volume';
import { DeliveryPlanning } from './planning';
import { TemplateDetailComponent } from './announcements/templates/detail';
import { TemplateListComponent } from './announcements/templates/list';
import { AnnouncementListComponent } from './announcements/list/list';
import { AnnouncementDetailComponent } from './announcements/list/detail';
import { CommUnsubscribeList } from './common-unsubscribes/list';
import {FinanceTransactionList} from '@app/admin/finance-transactions/list';
import {FinanceTransactionDetail} from '@app/admin/finance-transactions/detail';
import { ReportPODList } from './report/pod/list';
import { LinehaulLaneDetailScreen } from './planning/linehaul/lane-detail';
import {WarehouseRatePlanList} from '@app/admin/crossdock/rate-configuration/plan-list';
import {WarehouseRatePlanDetail} from '@app/admin/crossdock/rate-configuration/plan-detail';
import { ReportListScreen } from './report/viewer/list_screen';
import { WarehouseManifest } from './crossdock/manifests';
import { WarehouseManifestDetail } from './crossdock/manifests/detail';
import { WarehouseManifestPalletDetail } from './crossdock/manifests/detail/components/pallet-detail';
import { WarehouseManifestCartonDetail } from './crossdock/manifests/detail/components/carton-detail';

// @Injectable()
// export class CanActivateDashBoard implements CanActivate {
//   constructor(private auth: AuthGuard, private router: Router, private api: ApiService) { }
//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     let user = this.auth.getCurrentUser();
//     if (!RoleManager.allowLogin(user)) {
//       if (user) {
//         Log.e(`user ${user.email} is not allowed to access dashboard`);
//         this.api.logout();
//       }
//       return this.router.parseUrl('/login');
//     }
//     return true;
//   }
// }
import { PermissionCode } from '@const/PermissionCode';
import { DatTruckList } from '@app/admin/dat/truck/list';
import { FinPayableBatchList } from './fin/ap/batch-list';
import { FinPayableBatchDetail } from './fin/ap/batch-detail';
import { FinReceivableBatchList } from './fin/ar/batch-list';
import { FinReceivableBatchDetail } from './fin/ar/batch-detail';
import { WarehouseExternalRouteConnecting } from './crossdock/external-route-connecting';
import { CustomerWarehouseList } from './warehouses/customer-warehouse/list';
import { CustomerWarehouseDetail } from './warehouses/customer-warehouse/detail';
import { CustomerLaneList } from './clients/customer-lane/list';
import { CustomerLaneDetail } from './clients/customer-lane/detail';
import { DetailCarrierBidComponent } from './carrier-bids/components/detail-carrier-bid/detail-carrier-bid.component';
import { DatPostList } from './dat/post/list';
import { ReportPalletDoordash } from './export/pallet-doordash';
import { FinQuickbookStatementList } from './fin/quickbook-statement-bill/list';
import { FinQuickbookStatementDetail } from './fin/quickbook-statement-bill/detail';
import { BidSessionListComponent } from './bid-sessions/list';
import { BidSessionDetailComponent } from './bid-sessions/detail';
import { LinehaulLaneV2List } from './planning/linehaul-v2/list';
import { FactoringPartnerUrl } from './finance/factoring-partner-url';
import { WarehouseItemBarcodeIssues } from './crossdock/item-barcode-issues';

const dashboardRoutes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard - WARP Admin' },
    children: [
      { path: 'users', component: UserListComponent, data: { title: 'Users - WARP Admin', permissions: [PermissionCode.user.read] } },
      { path: 'users/add', component: UserDetailComponent, canDeactivate: [CheckCanQuit], data: { title: 'Users - WARP Admin', permissions: [PermissionCode.user.create] } },
      { path: 'users/:id', component: UserDetailComponent, canDeactivate: [CheckCanQuit], data: { title: 'Users - WARP Admin', permissions: [PermissionCode.user.read] } },
      { path: 'users/history/:id', component: AuditLog, data: { title: 'Users - WARP Admin' } },
      { path: 'drivers', component: DriverList, data: { title: 'Drivers - WARP Admin', permissions: [PermissionCode.driver.read] } },
      { path: 'drivers/add', component: DriverDetail, canDeactivate: [CheckCanQuit], data: { title: 'Drivers - WARP Admin', permissions: [PermissionCode.driver.create] } },
      { path: 'drivers/:id', component: DriverDetail, canDeactivate: [CheckCanQuit], data: { title: 'Drivers - WARP Admin', permissions: [PermissionCode.driver.read] } },
      { path: 'drivers/history/:id', component: AuditLog, data: { title: 'Drivers - WARP Admin' } },
      {
        path: 'locations', children: [
          { path: '', component: WarehouseList, data: { title: 'Locations - WARP Admin', permissions: [PermissionCode.warehouse.read] } },
          // { path: ':id', component: WarehouseDetail, data: { title: 'Locations - WARP Admin' } },
          { path: 'history/:id', component: AuditLog, data: { title: 'Locations - WARP Admin' } },
          { path: 'inventory/:id', component: WarehouseInventory, data: { title: 'Locations - WARP Admin', permissions: [PermissionCode.warehouse_inventory.read] } },
        ], data: { title: 'Locations - WARP Admin' }
      },
      { path: 'customer-locations', component: CustomerWarehouseList, data: {title: 'Customer Locations - WARP Admin', permissions: [PermissionCode.warehouse.read]} },
      { path: 'customer-locations/:id', component: CustomerWarehouseDetail, data: {title: 'Customer Locations - WARP Admin', permissions: [PermissionCode.warehouse.read]} },
      { path: 'customer-lanes', component: CustomerLaneList, data: { title: 'Customer Lanes - WARP Admin', permissions: [PermissionCode.client.read] }},
      { path: 'customer-lanes/:id', component: CustomerLaneDetail, data: { title: 'Customer Lanes - WARP Admin', permissions: [PermissionCode.client.read] }},
      //lazy-load
      { path: 'carriers', loadChildren: () => import('./carriers/module').then(m => m.CarrierModule), data: { title: 'Carriers - WARP Admin' } },
      { path: 'orders', loadChildren: () => import('./shipments/module').then(m => m.ShipmentModule), data: { title: 'Shipments - WARP Admin' } },
      { path: 'dispatch', loadChildren: () => import('./dispatch/module').then(m => m.DispatchModule), data: { title: 'Dispatch - WARP Admin' } },
      { path: 'carrier-sales', loadChildren: () => import('./carrier-sales/module').then(m => m.CarrierSalesModule), data: { title: 'Carrier Sales - WARP Admin' } },
      { path: 'carrier-sales-v2', loadChildren: () => import('./carrier-sales-v2/module').then(m => m.CarrierSalesModuleV2), data: { title: 'Carrier Sales V2- WARP Admin' } },
      { path: 'carrier-sales/carrier-sales-legacy/:id', component: DetailCarrierBidComponent, data: { title: 'Carrier Sales Legacy - WARP Admin' } },
      { path: 'bid-sessions', component: BidSessionListComponent, data: { title: 'Bid Sessions List - WARP Admin' }},
      { path: 'bid-sessions/:id', component: BidSessionDetailComponent, data: { title: 'Bid Sessions Detail - WARP Admin' }},
      //chỉ để tạm vài hôm để test => sau sẽ move sang repo portal factoring trên DEV
      { path: 'rate-confirmation', loadChildren: () => import('./rate-confirmation/rate-confirmation.module').then(m => m.RateConfirmationModule), data: { title: 'Rate Confirmation - WARP Admin' } },
      { path: 'shipment-queue', loadChildren: () => import('./shipment-queues/v2/module').then(m => m.ShipmentQueueModule) },
      { path: 'quick-orders', loadChildren: () => import('./quick-order/module').then(m => m.QuickOrdersModule) },
      { path: 'pools', loadChildren: () => import('./pools/pools.module').then(m => m.PoolsModule), data: { title: 'Pools - WARP Admin' } },
      { path: 'marketplace', loadChildren: () => import('./marketplace/module').then(m => m.MarketplaceModule) },

      { path: 'report/revenue', component: ReportRevenue, data: { title: 'Report Revenue - WARP Admin' } },
      { path: 'report/performance', component: OperationalPerformance, data: { title: 'Report Performance - WARP Admin' } },
      { path: 'report/loads-booked', component: LoadsBooked, data: { title: 'Loads Booked - WARP Admin' } },
      { path: 'report/revenue-booked', component: RevenueBooked, data: { title: 'Revenue Booked - WARP Admin' } },
      { path: 'report/pod', component: ReportPODList, data: { title: 'POD - WARP Admin' } },
      { path: 'zip-rate', component: ZipRate, data: { title: 'Zip Rate - WARP Admin' } },
      { path: 'customers', component: ClientList, data: { title: 'Customers - WARP Admin', permissions: [PermissionCode.client.read] } },
      { path: 'customers/add', component: ClientAdd, canDeactivate: [CheckCanQuit], data: { title: 'Add Customer - WARP Admin', permissions: [PermissionCode.client.create] } },
      { path: 'customers/:id', component: ClientDetail, canDeactivate: [CheckCanQuit], data: { title: 'Customers - WARP Admin', permissions: [PermissionCode.client.read] } },
      { path: 'customers/history/:id', component: AuditLog, data: { title: 'Customers - WARP Admin' } },
      { path: 'customers/:id/locations', component: ListLocation, data: { title: 'Customers - WARP Admin' } },
      { path: 'settings/two-factor-authentication', component: Setting2FA, data: { title: '2-FA Settings - WARP Admin' } },
      {
        path: 'finance', children: [
          { path: 'config/:id', component: FinanceConfigDetail, data: { title: 'Finance - WARP Admin' } },
          { path: 'invoices', component: ShipmentInvoiceList, data: { title: 'Invoice Shipments - WARP Admin' } },
          { path: 'transactions', component: FinanceTransactionList, data: { title: 'Finance - Transactions - WARP Admin' } },
          { path: 'transactions/:id', component: FinanceTransactionDetail, data: { title: 'Finance - Transaction - WARP Admin' } },
          { path: 'ap', component: FinPayableBatchList, data: { title: 'Accounts Payable Finance - WARP Admin' } },
          { path: 'ap/:id', component: FinPayableBatchDetail, data: { title: 'Accounts Payable Finance - Transaction - WARP Admin' } },
          { path: 'ar', component: FinReceivableBatchList, data: { title: 'Accounts Receivable Finance - WARP Admin' } },
          { path: 'ar/:id', component: FinReceivableBatchDetail, data: { title: 'Accounts Receivable Finance - WARP Admin' } },
          { path: 'qb-statement', component: FinQuickbookStatementList, data: { title: 'AP Statement - WARP Admin' } },
          { path: 'qb-statement/:id', component: FinQuickbookStatementDetail, data: { title: 'AP Statement - WARP Admin' } },
          { path: 'factoring-partner-url', component: FactoringPartnerUrl },
        ], data: { title: 'Finance - WARP Admin' }
      },
      { path: 'finance-v2', children: [
          { path: 'pod-confirmation', loadChildren: () => import('./pod-confirmation/module').then(m => m.PodConfirmationModule), data: { title: 'POD Confirmation - WARP Admin' } },
          { path: '', loadChildren: () => import('./finance-v2/module').then(m => m.FinanceModule) },
        ]
      },
      { path: 'freight-quote', component: FreightQuote, data: { title: 'Freight Quote - WARP Admin' } },
      { path: 'freight-quote-ftl', component: FreightQuoteFTL, data: { title: 'Freight Quote v2 - WARP Admin' } },
      { path: 'freight-quote-ftl/history', component: FreightQuoteHistoryList, data: { title: 'Freight Quote History - WARP Admin' } },
      { path: 'freight-quote-ftl/:id', component: FreightQuoteFTLHistoryRequest, data: { title: 'Freight Quote v2 - WARP Admin' } },
      { path: 'freight-quote-ftl/:id/book/:option_id', component: FreightQuoteBook, data: { title: 'Freight Quote v2 - WARP Admin' } },
      { path: 'cross-dock/inventories', component: CrossdockWarehouse, data: { title: 'Cross Dock Warehouses - WARP Admin' } },
      { path: 'cross-dock/shipments', component: WarehouseShipment, data: { title: 'Cross Dock Shipments - WARP Admin' } },
      { path: 'cross-dock/rate-configuration', component: WarehouseRatePlanList, data: { title: 'Cross Dock Rate Plans - WARP Admin' } },
      { path: 'cross-dock/rate-configuration/:id', component: WarehouseRatePlanDetail, canDeactivate: [CheckCanQuit], data: { title: 'Cross Dock Rates Configuration - WARP Admin' } },
      { path: 'cross-dock/manifests', component: WarehouseManifest },
      { path: 'cross-dock/manifests/:id', component: WarehouseManifestDetail },
      { path: 'cross-dock/manifests/:id/pallets/:itemId', component: WarehouseManifestPalletDetail },
      { path: 'cross-dock/manifests/:id/cartons/:itemId', component: WarehouseManifestCartonDetail },
      { path: 'cross-dock/external-route-connecting', component: WarehouseExternalRouteConnecting },
      { path: 'cross-dock/item-barcode-issues', component: WarehouseItemBarcodeIssues, data: { title: 'Cross Dock Shipments - WARP Admin' } },

      {
        path: 'routing-ltl', children: [
          { path: '', redirectTo: 'problems', pathMatch: 'full' },
          { path: 'problems', component: RoutingProblemList, data: { title: 'Routing LTL - WARP Admin' } },
          { path: 'problems/:id', component: RoutingProblemDetail, data: { title: 'Routing LTL - WARP Admin' } },
        ]
      },
      {
        path: 'planning', children: [
          { path: 'single', component: DeliveryPlanning, data: { title: 'Delivery Planning - WARP Admin' } },
          { path: 'appointment', loadChildren: () => import('./shipment-queue-v2/module').then(m => m.ShipmentQueueV2Module) },
          { path: 'need-routing', component: NeedRoutingScreen, data: { title: 'Delivery Planning - WARP Admin' } },
          { path: 'volume', component: LtlVolumeScreen, data: { title: 'Delivery Planning - WARP Admin' } },
          { path: 'linehauls', component: LinehaulLaneListScreen, data: { title: 'Linehaul Planning - WARP Admin' } },
          { path: 'linehauls/:id', component: LinehaulLaneDetailScreen, data: { title: 'Linehaul Planning - WARP Admin' } },
          { path: 'linehauls-v2', component: LinehaulLaneV2List, data: { title: 'Linehaul Planning V2 - WARP Admin' } },
          { path: 'linehauls-v2/:id', component: LinehaulLaneDetailScreen, data: { title: 'Linehaul Planning V2 - WARP Admin' } },
          { path: 'sessions', component: DeliveryPlanningScreen, data: { title: 'Delivery Planning - WARP Admin' } },
          { path: 'sessions/:id', component: PlanningSessionDetailScreen, data: { title: 'Delivery Planning - WARP Admin' } },
        ]
      },
      {
        path: 'announcements', children: [
          { path: '', component: AnnouncementListComponent, data: { title: 'Announcements - WARP Admin' } },
          { path: ':id', component: AnnouncementDetailComponent, data: { title: 'Announcements - WARP Admin' } },
        ]
      },
      {
        path: 'templates', children: [
          { path: '', component: TemplateListComponent, data: { title: 'Templates - WARP Admin' } },
          { path: ':id', component: TemplateDetailComponent, data: { title: 'Templates - WARP Admin' } },
        ]
      },
      { path: 'comm-unsubscribes', component: CommUnsubscribeList, data: { title: 'Comm Unsubscribes - WARP Admin' } },
      { path: 'report/viewer', component: ReportListScreen, data: { title: 'Report - WARP Admin' } },
      { path: 'report/viewer/:id', component: ReportViewerScreen, data: { title: 'Report - WARP Admin' } },

      { path: 'dat', children: [
          { path: 'truck', component: DatTruckList, data: { title: 'DAT Truck - WARP Admin' } },
          { path: 'post', component: DatPostList, data: { title: 'DAT Posting - WARP Admin' } },
        ]
      },
      { path: 'developers', loadChildren: () => import('./developers/module').then(m => m.DeveloperModule) },
      { path: 'export', loadChildren: () => import('./export/module').then(m => m.ExportModule)},
      { path: '', loadChildren: () => import('./textract-template/module').then(m => m.TextractTemplateModule) },
      { path: 'dedicated-lanes', loadChildren: () => import('./dedicated-lanes/dedicated-lanes.module').then(m => m.DedicatedLanesModule) },

      { path: '**', component: PageError },
    ],
    // canActivate: [CanActivateDashBoard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
  // providers: [CanActivateDashBoard]
})
export class DashboardRoutingModule { }
