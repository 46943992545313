import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseQuickbooksComponent } from "../base";
import { Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { InputHelper } from "@services/input-helper";

@Component({
  selector: 'create-quickbooks-customer',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class CreateQuickbooksCustomer extends BaseQuickbooksComponent {

  @Input() accountInfo;

  protected formGroupDeclaration: FormGroupDeclaration = {
    companyName: { label: "Company name", required: true },
    email: { label: "Email", notAcceptEmpty: true, validators: Validators.email },
    phone: { label: "Phone", notAcceptEmpty: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone },
    firstName: { label: "First Name", notAcceptEmpty: true },
    lastName: { label: "Last Name", notAcceptEmpty: true },
    address: { label: "Address", notAcceptEmpty: true },
    notes: { label: "Notes", notAcceptEmpty: true },
  };

  constructor(private modal: NzModalRef) {
    super();
  }

  ngOnInit(): void {
    this.model = {
      companyName: this.accountInfo?.name,
      email: this.accountInfo?.primaryContact?.email,
      phone: this.accountInfo?.primaryContact?.phone,
      firstName: this.accountInfo?.primaryContact?.firstName,
      lastName: this.accountInfo?.primaryContact?.lastName,
      address: this.accountInfo?.address,
    }
    super.ngOnInit();
  }

  public row1: FormRow = {
    columns: [
      {key: 'email', span: 12 },
      {key: 'phone', span: 12 },
    ]
  };

  public row2: FormRow = {
    columns: [
      {key: 'firstName', span: 12 },
      {key: 'lastName', span: 12 },
    ]
  };

  onBtnClose() {
    this.closeDialog();
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  onBtnCreateCustomer() {
    if (!this.needUpdate || this.onProgress) return;
    const data = this.getFormData_JSON(true);
    if (!data) return;
    data.finAccountId = this.accountInfo?.finAccountId;
    const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/create-customer`);
    this.startProgress();
    return this.api.POST(url, data).subscribe(
      (resp) => {
        this.showSuccess(resp);
        this.closeDialog();
        this.stopProgress();
      },
      (err) => {
        this.handleErrorQueryQuickbooks(err);
        this.stopProgress();
      }
    );
  }
  
}
