<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>

<div *ngIf="!onProgress" class="dashboard-child-container no-padding">
  <div class="box-line bottom15" style="width: 100%; padding: 20px">
    <div style="width: 100%" class="flex flex-space-between">
      <div class="flex flex-wrap">
        <div>
          <a [routerLink]="[routeAdminFinQBStatement]" class="text-secondary">Statement</a>
          <ng-container *ngIf="model"> / <span>{{ showStatementCode() }}</span></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="detail-body">
    <div class="flex flex-space-between">
      <div>
        <div class="f15 text-label">Statement {{ showStatementCode() }}</div>
        <div class="created-time top10" *ngIf="showStatementCreatedTime()">Created on {{ showStatementCreatedTime() }}</div>
      </div>
      <div>
        <div><span class="text-secondary">Total Payable Amount</span><span class="total-amt left10">{{ showTotalAmount() }}</span></div>
        <div *ngIf="isStatementCreated" style="margin-top: 16px; display: flex; flex-direction: row-reverse;">
          <button nz-button nzType="primary"
            [nzLoading]="isCreatingBill" [disabled]="isCreatingBill"
            (click)="onBtnCreateQBBill()"
          >
            Create bill on Quickbooks
          </button>
        </div>
        <div *ngIf="isStatementSubmitted" style="margin-top: 16px; display: flex; flex-direction: row-reverse; color: #8D8D8D !important;">
          <warp-when-by [model]="model.submittedBy" [viewTemplate]="tplSubmittedBy"></warp-when-by>
          <ng-template #tplSubmittedBy let-hasData="hasData" let-displayInfo="displayInfo">
            <div class="text-secondary" style="font-style: italic;">Bill created on Quickbooks on {{ showSubmittedTime(model?.submittedBy?.when) }} by {{displayInfo.createdBy}}</div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="separator h top15 bottom20"></div>
    <!-- Overview -->
    <div>
      <div class="text-label">Overview</div>
      <div nz-row [nzGutter]="16" class="top15">
        <div nz-col nzSm="3" class="text-secondary">Date range</div>
        <div nz-col nzSm="16">{{ showRouteCompletedWindow() }}</div>
      </div>
      <div nz-row [nzGutter]="16" class="top10">
        <div nz-col nzSm="3" class="text-secondary">Carrier/Payee</div>
        <div nz-col nzSm="16">{{ showPayee() }}</div>
      </div>
      <div nz-row [nzGutter]="16" class="top10">
        <div nz-col nzSm="3" class="text-secondary">Total routes</div>
        <div nz-col nzSm="16">{{ showTotalRoutes() }}</div>
      </div>
    </div>
    <div class="separator h top15 bottom20"></div>

    <!-- Details -->
    <div>
      <div class="text-label bottom15">Details</div>
      <nz-table #nzTable 
        nzBordered="true" nzSize="small"
        [nzData]="listRoute"
        [nzFrontPagination]="false"
        style="overflow-x: auto;"
      >
        <thead>
          <tr>
            <th>Route</th>
            <th>Shipment</th>
            <th>Payable Amount</th>
            <th nzWidth="220px">POD Status</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let job of listRoute; let i = index">
            <tr>
              <td>
                <div class="flex flex-space-between">
                  <a [href]="goToDispatch(job?.id)" target="_blank">{{ job.code }}</a>
                  <div class="text-secondary">
                    <i *ngIf="shouldShowJobWarning(job)" nz-icon nzType="warning" nzTheme="outline" nz-tooltip nzTooltipTitle="Route is not completed" style="color: #faad14;"></i>
                    {{ getStatusJob(job.status) }}
                  </div>
                </div>
              </td>
              <td>{{ showShipments(job) }}</td>
              <td>{{ showDisplayMoney(job.amt) }}</td>
              <td>
                <div class="flex flex-space-between">
                  <div>
                    <nz-tag class="pod-status" [ngClass]="job.podStatus">{{ showPODStatus(job) }}</nz-tag>    
                  </div>
                  <div *ngIf="shouldCompletePOD(job)">
                    <a [href]="goToConfirmPOD(job?.id)" target="_blank">
                      <i nz-icon nzType="right-circle" nzTheme="outline" nz-tooltip nzTooltipTitle="Go to complete POD" style="font-size: 18px;"></i>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>

    <!-- List existed bill on quickbook -->
    <div *ngIf="hasFinJobCreatedBill">
      <div class="separator h top15 bottom20"></div>
      <div class="text-label bottom15">Existed Bill On Quickbooks <code>({{ showExistedBill_TotalAmt() }})</code></div>
      <nz-table #nzTable_ExistedBill 
        nzBordered="true" nzSize="small"
        [nzData]="listJobHasBeenCreatedBill"
        [nzFrontPagination]="false"
        style="overflow-x: auto;"
      >
        <thead>
          <tr>
            <th>Route</th>
            <th>Payable Amount</th>
            <th nzWidth="220px">POD Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let job of listJobHasBeenCreatedBill; let i = index">
            <tr>
              <td>
                <div class="flex flex-space-between">
                  <a [href]="goToDispatch(job?.id)" target="_blank">{{ job.code }}</a>
                  <div class="text-secondary">
                    <i *ngIf="shouldShowJobWarning(job)" nz-icon nzType="warning" nzTheme="outline" nz-tooltip nzTooltipTitle="Route is not completed" style="color: #faad14;"></i>
                    {{ getStatusJob(job.status) }}
                  </div>
                </div>
              </td>
              <td>{{ showDisplayMoney(job.amt) }}</td>
              <td><nz-tag class="pod-status" [ngClass]="job.podStatus">{{ showPODStatus(job) }}</nz-tag></td>
              <td>
                <a [href]="goToBatchDetail(job)" target="_blank">See detail</a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>

</div>