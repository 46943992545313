<div class="title" [ngClass]="{'warning': hasIssue}">Financial issues <i *ngIf="hasIssue" nz-icon nzType="exclamation-circle" nzTheme="outline"></i></div>
<div notes-component #noteComponent
  [subjectId]="jobId" [subjectType]="'carrier_cost'"
  [allowDeletion]="true" [allowDeleteItemNotMine]="true"
  (fetchDataDone)="onNoteFetchingDone($event)"
  (itemAdded)="onNoteItemAdded($event)"
  (itemRemoved)="onNoteItemRemoved($event)"
  [showMessageAddSuccess]="showMessageAddSuccess"
  [beforeAdd]="beforeAddNote"
  [shouldShowAction]="requirePermissions([PermissionCode.payment.edit])"
  [shouldShowNoteForm]="requirePermissions([PermissionCode.payment.edit])"
  [hasUploadFile]="true">
</div>